<template>
  <div class="col-12 g c col-lg-4">
    <br><br>
    <div class="card card-body">
        <img :src="require('@/assets/images/banner.png')" style="width: 50%; margin: 0px auto; padding: 20px" alt="">
        <div class="alert alert-danger text-center g" v-if="error">
            {{ error }}
        </div>
        <div class="form-group g">
          <h5 for="">اسم المستخدم</h5>
          <input type="text"
            class="form-control form-control-lg" v-model="phone" placeholder="اكتب رقم الجوال هنا...">
        </div>
        <div class="form-group g">
          <h5 for="">كلمة المرور</h5>
          <input type="password"
            class="form-control form-control-lg" v-model="password" placeholder="اكتب كلمة المرور هنا...">
        </div>
        <div class="form-group g" v-if="otp">
          <h5 for="">OTP</h5>
          <input type="password"
            class="form-control form-control-lg" v-model="code" placeholder="اكتب الكود بعد تسجيل الدخول">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="login()">
                <i class="fa fa-check"></i>
                تسجيل الدخول
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    created(){

    },
    data(){
        return {
            phone: "",
            password: "",
            error: null,
            code: "",
            otp: false
        }
    },
    methods: {
        login(){
            var g = this;
            g.error = null;
            $.post(api + '/admin/login', {
                username: g.phone,
                password: g.password,
                code: g.code
            }).then(function(r){
                if(r.status != 100){
                    g.error = r.response
                    if(r?.response?.includes("OTP")){
                        g.otp = true;
                    }
                }else{
                    localStorage.setItem("jwt", r.response)
                    window.location = '/'
                }
            }).fail(function(){
                g.error = "حدث مشكلة في الاتصال"
            })
        }
    }
}
</script>

<style>

</style>